import React from 'react';
import {ViolationModel} from '../../../domain/models/Violation.model';
import {Violation} from './Violation';

export enum ViolationsDataHook {
  root = 'ViolationsDataHook.root',
}

export const Violations = ({violations}: {violations: ViolationModel[]}) => {
  return (
    <div data-hook={ViolationsDataHook.root}>
      {violations.map((violation, index) => (
        <Violation key={index} violation={violation} />
      ))}
    </div>
  );
};
