import React from 'react';
import {ViolationModel} from '../../../domain/models/Violation.model';

export enum ViolationDataHook {
  root = 'ViolationNotificationDataHook.root',
  message = 'ViolationNotificationDataHook.message',
}

export const Violation = ({violation}: {violation: ViolationModel}) => {
  return (
    <div data-hook={ViolationDataHook.root}>
      <span data-hook={ViolationDataHook.message}>{violation.description}</span>
    </div>
  );
};
